<template>
    <div>
        <!-- Table Container Card -->
        <b-card no-body class="mb-0">
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->


                    <!-- Search -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..."
                                :class="{ red_border: notEmpty }" />
                        </div>
                    </b-col>
                    <b-col cols="12" md="1">
                        <div class="d-flex justify-content-end">
                            <button @click="getCategories('')" class="btn btn-primary">
                                Filter
                            </button>
                        </div>
                    </b-col>
                </b-row>
            </div>

            <b-row class="mb-2 ml-1">
                <b-col>
                    <b-button size="sm" @click="openAddCategory()" class="btn btn-warning mr-2">
                        Add Category
                    </b-button>
                </b-col>

            </b-row>


            <b-table ref="refUserListTable" class="position-relative" responsive primary-key="id"
                :items="listCategories" :fields="fields" empty-text="No matching records found" show-empty :per-page="0"
                id="my-table" :current-page="currentPage">
                <!-- Column: Actions -->
                <template #cell(actions)="{ item }">
                    <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL"
                        transition="scale-transition" :offset-y="true">
                        <template #button-content>
                            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                        </template>
                        <template style="padding: 0" v-slot:activator="{ on, attrs }">
                            <b-btn color="primary" v-bind="attrs" v-on="on" icon ripple>
                            </b-btn>
                        </template>
                        <b-list-group style="padding: 0px; margin-bottom: 0px" dense rounded>
                            <b-list-group-item style="padding: 0" class="urlPagina" :ripple="false">
                                <b-list-group-item class="font-weight-bold"
                                    style="border: none; padding: 5px; color: #7367f0" @click="openEditCategory(item)">
                                    <feather-icon icon="CheckIcon" />
                                    Edit
                                </b-list-group-item>
                            </b-list-group-item>
                            <b-list-group-item style="padding: 0" class="urlPagina" :ripple="false">
                                <b-list-group-item class="font-weight-bold"
                                    style="border: none; padding: 5px; color: #7367f0" @click="DeleteCategoriesDocuments(item)">
                                    <feather-icon icon="CheckIcon" />
                                    Delete
                                </b-list-group-item>
                            </b-list-group-item>
                        </b-list-group>

                    </b-dropdown>
                </template>
            </b-table>

            <div class="pagination_block">
                <nav aria-label="Page navigation example">
                    <ul class="pagination justify-content-center">
                        <li class="page-item" :class="{ disabled: pagination.prev_page_url == null }">
                            <button type="button" class="page-link"
                                @click="getItems(pagination.first_page_url)">&laquo;</button>
                        </li>
                        <li class="page-item" :class="{ disabled: pagination.prev_page_url == null }">
                            <button type="button" class="page-link"
                                @click="getItems(pagination.prev_page_url)">Previous</button>
                        </li>
                        <li class="page-item" v-if="current_page - 2 > 0">
                            <button type="button" class="page-link"
                                @click="getItems(pagination.path + '?page=' + (current_page - 2))">{{ current_page
                                    - 2
                                }}</button>
                        </li>
                        <li class="page-item" v-if="pagination.prev_page_url">
                            <button type="button" class="page-link" @click="getItems(pagination.prev_page_url)">{{
                                current_page - 1
                            }}</button>
                        </li>
                        <li class="page-item">
                            <button type="button" class="page-link"
                                :class="{ current: pagination.current_page == current_page }">{{
                                    current_page
                                }}</button>
                        </li>
                        <li class="page-item" v-if="pagination.next_page_url">
                            <button type="button" class="page-link" @click="getItems(pagination.next_page_url)">{{
                                current_page + 1
                            }}</button>
                        </li>
                        <li class="page-item" v-if="current_page + 2 <= last_page">
                            <button type="button" class="page-link"
                                @click="getItems(pagination.path + '?page=' + (current_page + 2))">{{ current_page
                                    + 2
                                }}</button>
                        </li>
                        <li class="page-item" :class="{ disabled: pagination.next_page_url == null }">
                            <button type="button" class="page-link"
                                @click="getItems(pagination.next_page_url)">Next</button>
                        </li>
                        <li class="page-item" :class="{ disabled: pagination.next_page_url == null }">
                            <button type="button" class="page-link"
                                @click="getItems(pagination.last_page_url)">&raquo;</button>
                        </li>
                    </ul>
                </nav>
            </div>

        </b-card>

        <b-modal id="modal-edit-category" centered hide-footer>
            <h4>{{ isEditCategory(categoryDocument) == true ? "Edit " : "Add " }}Category Information</h4>
            <hr>
            <b-form-group v-if="isEditCategory(categoryDocument) == true">
                <h5>Id</h5>
                <b-form-input disabled size="sm" v-model="categoryDocument.id" type="text" />
            </b-form-group>
            <b-form-group>
                <h5>Description #</h5>
                <b-form-input size="sm" v-model="categoryDocument.description" type="text" />
            </b-form-group>

            <b-button v-if="isEditCategory(categoryDocument) == false" @click="AddCategoriesDocuments(categoryDocument)"
                size="sm" class="btn-success" style="float: right;">
                Save
            </b-button>
            <b-button v-if="isEditCategory(categoryDocument) == true"
                @click="UpdateCategoriesDocuments(categoryDocument)" size="sm" class="btn-success"
                style="float: right;">
                Save
            </b-button>
        </b-modal>

    </div>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BListGroup,
    BListGroupItem,
    BFormSelect,
    BFormGroup
} from "bootstrap-vue";
import vSelect from "vue-select";

import UserListAddNew from "@core/components/infoClients/UserListAddNew";
import { getMessageError } from '@core/utils/utils'
export default {
    components: {
        UserListAddNew,
        BCard,
        BRow,
        BCol,
        BFormInput,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        BListGroup,
        BListGroupItem,
        BFormSelect,
        vSelect,
        BFormGroup
    },

    data() {
        return {
            perPage: 5,
            pageOptions: [3, 5, 10],
            currentPage: 1,
            listCategories: [],
            search: "",
            fields: [
                {
                    key: "id",
                    label: 'Id',
                    sortable: true,
                },
                {
                    key: "description",
                    label: 'Description',
                    sortable: true,
                },
                'actions'
            ],
            categoryDocument: {
                id: null,
                description: null
            },
            totalItems: 0,
            pagination: {},
            last_page: 0,
            current_page: 1,
            searchQuery: "",
            notEmpty: false,
            currentUrl: "",
        };
    },
    methods: {
        getItems(url) {
            this.getCategories(url)
        },
        getCategories(url = "") {
            if (url == "") {
                url = `/admin/panel/category_addional_documents_driver_get_list`;
            }
            this.currentUrl = url;
            this.$swal({
                title: "Please, wait...",
                didOpen: () => {
                    this.$swal.showLoading();
                },
            });

            this.$http
                .get(url,
                    {
                        params: {
                            data: this.searchQuery,
                        },
                    }

                )
                .then((response) => {
                    this.listCategories = response.data.data.data;
                    this.totalItems = response.data.data.total;
                    this.current_page = response.data.data.current_page
                    this.last_page = response.data.data.last_page
                    this.pagination = response.data.data
                    this.$swal.close();
                })
                .catch((error) => {
                    this.$swal({
                        title: getMessageError(error),
                        icon: "error",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                    }); 
                }
                );
        },
        AddCategoriesDocuments(data) {

            let url = `/admin/panel/category_addional_documents_driver_add`;

            this.$swal({
                title: "Please, wait...",
                didOpen: () => {
                    this.$swal.showLoading();
                },
            });
            this.$http
                .post(url, data)
                .then((response) => {
                    this.$bvModal.hide("modal-edit-category");
                    this.getCategories(this.currentUrl);
                })
                .catch((error) => {
                    this.$swal({
                        title: getMessageError(error),
                        icon: "error",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                    });                  
                }
                );
        },
        UpdateCategoriesDocuments(data) {

            let url = `/admin/panel/category_addional_documents_driver_update`;

            this.$swal({
                title: "Please, wait...",
                didOpen: () => {
                    this.$swal.showLoading();
                },
            });
            this.$http
                .post(url, data)
                .then((response) => {
                    this.getCategories(this.currentUrl);
                })
                .catch((error) => {
                    this.$swal({
                        title: getMessageError(error),
                        icon: "error",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                    });
                }
                );
        },
        DeleteCategoriesDocuments(data) {

            let url = `/admin/panel/category_addional_documents_driver_delete`;

            this.$swal({
                title: "Please, wait...",
                didOpen: () => {
                    this.$swal.showLoading();
                },
            });
            this.$http
                .post(url, data)
                .then((response) => {
                    this.getCategories(this.currentUrl);
                })
                .catch((error) => {
                    this.$swal({
                        title: getMessageError(error),
                        icon: "error",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                    });
                }
                );
        },
        openEditCategory(item) {
            this.$bvModal.show("modal-edit-category");
            this.categoryDocument = item;
        },
        openAddCategory() {
            this.$bvModal.show("modal-edit-category");
            this.categoryDocument = {
                id: null,
                description: null
            };
        },
        isEditCategory(item) {
            let res = false;
            if (item != null) {
                if (item.id != null) {
                    res = true;
                }
            }
            return res;
        }
    },
    computed: {
        rows() {
            return this.listCategories.length;
        },
    },
    mounted() {
        this.getCategories();
    },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}

.urlPagina {
    text-decoration: none;
    color: #7367f0;
}

.urlPagina:hover {
    background: linear-gradient(118deg,
            #7367f0,
            rgba(115, 103, 240, 0.7)) !important;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
}

.list-group-item:hover {
    background: linear-gradient(118deg,
            #7367f0,
            rgba(115, 103, 240, 0.7)) !important;
    color: #fff !important;
    cursor: pointer;
}

.urlPagina::before {
    background-color: currentColor !important;
    bottom: 0;
    content: "";
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.box {
    box-shadow: 0px 14px 20px 0px rgba(143, 143, 143, 0.2) !important;
}

.current {
    background: #7367f0;
    color: white;
}

.red_border {
    border: 1px solid red;
    border-radius: 6px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>